import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { CampaignTemplateCard } from 'src/app/models/apiResponse';
import { AppService } from 'src/app/services/app-service/app.service';

@Component({
  selector: 'app-campaigns-preview',
  templateUrl: './campaigns-preview.component.html',
  styleUrls: ['./campaigns-preview.component.scss'],
})
export class CampaignsPreviewComponent  implements OnInit {

  @Input() selectedAd: CampaignTemplateCard | null;
  constructor(
    public modalController: ModalController,
    private router: Router,
    public appService: AppService
  ) { }

  ngOnInit() {}

  close(): void {
    this.modalController.dismiss();
  }

  openAdwizard(): void {
    this.close();
    this.router.navigate(['/ad-wizard'], { state: { adId: this.selectedAd?.id } });
  }

}
