import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-app-tracking-info',
  templateUrl: './app-tracking-info.component.html',
  styleUrls: ['./app-tracking-info.component.scss'],
})
export class AppTrackingInfoComponent  implements OnInit {

  @Input() infoData: string;
  constructor(private modalController: ModalController) {}

  dismissModal() {
    this.modalController.dismiss();
  }

  ngOnInit() {}

}
