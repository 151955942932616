import { Injectable } from '@angular/core';
import { AppService } from '../app-service/app.service';
import { FcmService } from '../fcm/fcm.service';
import { EventsService } from '../events/events.service';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { dashboardPageUrl } from 'src/app/constants/app-constants';
import { HttpErrorResponse } from '@angular/common/http';
import { CustomErrorResponse } from 'src/app/models/interface';
import { LoginResponse } from 'src/app/models/apiResponse';
import { Config } from '../config';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private appService: AppService,
    private fcmService: FcmService,
    private events: EventsService,
    private navController: NavController,
    private translate: TranslateService
  ) { }

  // Login existing user, demo user and new user signup into app
  async login(newUser: boolean, isDemoLogin = false, showLoader = false): Promise<void> {
    if (showLoader) {
      await this.appService.showLoader();
    }
    await this.appService.setStorage('firstUser', 'false');
    try {
      if (isDemoLogin && this.appService.onboardingIntroConfig?.default_team) {
        await this.appService.fetchTeam({id: this.appService.onboardingIntroConfig.default_team});
      } else {
        await this.appService.selectTeam();
      }
      await this.appService.fetchUserDetails();
      this.fcmService.initPush(); //Initialize push notifications
      let navUrl = newUser ? '/store' : dashboardPageUrl;
      this.events.publish('appsFlyer:loginEvent');
      this.events.publish('customers:fetch');
      if (isDemoLogin) {
        navUrl = dashboardPageUrl;
        this.configureDemoAccountSettings();
      }
      this.navController.navigateRoot(navUrl);
      await this.appService.hideLoader();
    } catch {
      this.appService.presentToast(this.translate.instant('error_messages.failed_select_account'));
      await this.appService.hideLoader();
    }
  }

  async demoLogin(): Promise<void> {
    await this.appService.showLoader();
    await this.appService.api(Config.demoCredentials, Config.apiUrl.login).subscribe({
      next: async (response: LoginResponse) => {
        await this.appService.setStorage('auth', response.jwt);
        this.login(false, true);
      },
      error: async (e: HttpErrorResponse) => {
        await this.appService.hideLoader();
        const errorResponse: CustomErrorResponse = e?.error;
        this.appService.presentToast(errorResponse?.error?.message || errorResponse?.message)
      }
    });
  }

  configureDemoAccountSettings(): void {
    this.appService.setStorage('isDemoLogin', true);
  }
}
