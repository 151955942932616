import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { environment as env } from 'src/environments/environment';
import { AppService } from '../app-service/app.service';
import { EventsService } from '../events/events.service';
import { AFEvent, AFInit, AppsFlyer} from 'appsflyer-capacitor-plugin';
import {AppTrackingStatusResponse, AppTrackingTransparency,} from 'capacitor-plugin-app-tracking-transparency';
import { UserResponse } from 'src/app/models/apiResponse';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AppTrackingInfoComponent } from 'src/app/components/app-tracking-info/app-tracking-info.component';

declare const AF;

@Injectable({
  providedIn: 'root'
})
export class AppsflyerService {

  constructor(
    private appService: AppService,
    private events: EventsService,
    public modalController: ModalController,
    private translate: TranslateService,
  ) { }

  initAppsFlyer() {
    if (Capacitor.isNativePlatform()) {
      const appID = Capacitor.getPlatform() === 'ios' ? env.iOSAppId : env.appId
      const afConfig: AFInit = {
        appID,
        devKey: env.appsFlyerDevKey,
        isDebug: env.development,
        waitForATTUserAuthorization: 10, // for iOS 14 and higher
        minTimeBetweenSessions: 6, // default 5 sec
        registerOnDeepLink: true,
        registerConversionListener: true,
        registerOnAppOpenAttribution: false,
        deepLinkTimeout: 4000, // default 3000 ms
        useReceiptValidationSandbox: true, // iOS only
        useUninstallSandbox: true // iOS only
      };

      AppsFlyer.initSDK(afConfig);

      this.initiateTrackingPermissionCheck();
    }
    
    this.subscribeAppsFlyerEvents();
  }

  initiateTrackingPermissionCheck(): void {
    if (Capacitor.getPlatform() === 'ios') {
      this.checkAppTrackingPermission();
    }
  }

  async checkAppTrackingPermission(): Promise<void> {
    const appTrackingStatus: AppTrackingStatusResponse = await AppTrackingTransparency.getStatus();

    if (appTrackingStatus.status === 'notDetermined') {
      this.showAppTrackingPopup();
    } else if (appTrackingStatus.status === 'denied') {
      this.showAppTrackingEnableSettingsPopup();
    }
  }

  async showAppTrackingEnableSettingsPopup(): Promise<void> {
    const infoModal = await this.modalController.create({
      component: AppTrackingInfoComponent,
      cssClass: 'app-tracking-info-modal',
      componentProps: {
        infoData: this.translate.instant('app_tracking_permission.guide')
      }
    });
    await infoModal.present();
  
    infoModal.onDidDismiss().then((result) => {
      if (result) {
        AppTrackingTransparency.requestPermission();
      }
    });
  }

  async showAppTrackingPopup(): Promise<void> {
    const infoModal = await this.modalController.create({
      component: AppTrackingInfoComponent,
      cssClass: 'app-tracking-info-modal',
      componentProps: {
        infoData: this.translate.instant('app_tracking_permission.info')
      }
    });
    await infoModal.present();
  
    infoModal.onDidDismiss().then((result) => {
      if (result) {
        AppTrackingTransparency.requestPermission();
      }
    });
  }

  subscribeAppsFlyerEvents(): void {
    this.events.subscribe('appsFlyer:loginEvent', async () => {
      const data: AFEvent = {
        eventName: 'login',
        eventValue: {
          user_id: this.appService.currentUser.id
        }
      };
      this.logAppsFlyerEvent(data);
    });

    this.events.subscribe('appsFlyer:registerEvent', (emailID: string) => {
      const data: AFEvent = {
        eventName: 'create_account',
        eventValue: {
          user_email_id: emailID
        }
      };
      this.logAppsFlyerEvent(data);
    });

    this.events.subscribe('appsFlyer:deleteAccountEvent', (user: UserResponse) => {
      const data: AFEvent = {
        eventName: 'delete_account',
        eventValue: {
          user_id: user.id
        }
      };
      this.logAppsFlyerEvent(data);
    });

    this.events.subscribe('appsFlyer:changeLanguageEvent', (language: string) => {
      const data: AFEvent = {
        eventName: 'change_language',
        eventValue: {
          user_id: this.appService.currentUser.id,
          user_language: language
        }
      };
      this.logAppsFlyerEvent(data);
    });

    this.events.subscribe('appsFlyer:createIntegrationEvent', (displayName: string) => {
      const data: AFEvent = {
        eventName: 'create_integration',
        eventValue: {
          user_id: this.appService.currentUser.id,
          integration_display_name: displayName
        }
      };
      this.logAppsFlyerEvent(data);
    });

    this.events.subscribe('appsFlyer:deleteIntegrationEvent', (displayName: string) => {
      const data: AFEvent = {
        eventName: 'delete_integration',
        eventValue: {
          user_id: this.appService.currentUser.id,
          integration_display_name: displayName
        }
      };
      this.logAppsFlyerEvent(data);
    });

    this.events.subscribe('appsFlyer:addKeywordsEvent', (domain: string) => {
      const data: AFEvent = {
        eventName: 'add_keywords',
        eventValue: {
          user_id: this.appService.currentUser.id,
          domain
        }
      };
      this.logAppsFlyerEvent(data);
    });

    this.events.subscribe('appsFlyer:bookAppointmentEvent', () => {
      const data: AFEvent = {
        eventName: 'book_appointment',
        eventValue: {
          user_id: this.appService.currentUser.id
        }
      };
      this.logAppsFlyerEvent(data);
    });
  }

  logAppsFlyerEvent(data) {
    if (Capacitor.isNativePlatform()) {
      AppsFlyer.logEvent(data);
    } else {
      const webData = {
        eventType: 'EVENT',
        ...data
      }
      AF('pba', 'event', webData);
    }
  }
}
