export enum IntegrationStatus {
  active = 'active',
  pending_authorization = 'pending_authorization',
  pending_reauthorization = 'pending_reauthorization',
  pending_account_identifier = 'pending_account_identifier',
  pending_account_configuration = 'pending_account_configuration',
  paused = 'paused',
  stopped = 'stopped',
}

export enum IntegrationAuthMethods {
  oauth2 = 'oauth2',
  user_redirect = 'user_redirect',
  credentials = 'credentials',
  facebook_sdk = 'facebook_sdk'
}

export enum OrderStatus {
  all = 'all', // Used only in front end for filter
  cancelled = 'cancelled',
  paid = 'paid',
  pending = 'pending',
  processing = 'processing',
  onhold = 'onhold',
  refunded = 'refunded',
  failed = 'failed',
  trash = 'trash',
  done = 'done'
}

export enum DateFilters {
  day = 'Day',
  week = 'Week',
  month = 'Month',
  year = 'Year',
  yearToDate = 'YTD'
}

export enum UserVerificationStatus {
  pending_email_verification = 'pending_email_verification',
  fully_authenticated = 'fully_authenticated'
}

export enum BusinessTypes {
  ecommerce = 'ECOMMERCE',
  retail = 'RETAIL',
  food = 'FOOD',
  finance = 'FINANCE',
  it = 'IT',
  development = 'DEVELOPMENT',
  sales = 'SALES',
  unknown = 'UNKNOWN',
}

export enum ChartTypes {
  line_chart = 'line',
  bar_chart = 'bar'
}

export enum SocialLoginProviders {
  apple = 'apple',
  google = 'google',
  facebook = 'facebook',
  twitter = 'twitter',
  bankid = 'bankid'
}

export enum AdwizardPreviewMode {
  local = 'local',
  cache = 'cache',
  external_uri = 'external_uri'
}