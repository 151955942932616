import { environment as env } from 'src/environments/environment';

export class Config {
  // Configurations
  public static appDebug = true;
  public static defaultLanguage = 'en';
  public static staticApiUrl = env.apiDomain + env.apiVersion + '/';
  public static loadingSpinner = 'crescent';
  public static facebookMessengerId = 'effektify';
  public static websiteURL = 'https://effektify.com';

  // Validation rules
  public static validation = {
    email: '^.+@[^/\\s]*?(?:\\.[a-zA-Z]{2,})+$',
    url: '^(?:https?:\\/\\/)?[^/\\s]*?(?:\\.[a-z]{2,})+$',
  }

  // Demo account credentials
  public static demoCredentials = {
    email: 'demo@effektify.com',
    password: 'nianDeCO'
  }

  // API Url's
  public static apiUrl = {
    ping: {
      method: 'get',
      url: 'ping',
      dynamicApiUrl: false,
    },
    login: {
      method: 'post',
      url: 'login',
      dynamicApiUrl: false,
    },
    forgotPassword: {
      method: 'post',
      url: 'users/forgot-password',
      dynamicApiUrl: false,
    },
    me: {
      method: 'get',
      url: 'me',
      dynamicApiUrl: false,
    },
    createTeam: {
      method: 'post',
      url: 'teams',
      dynamicApiUrl: false,
    },
    teams: {
      method: 'get',
      url: 'teams',
      dynamicApiUrl: false,
    },
    team: {
      method: 'get',
      url: 'teams/',
      dynamicApiUrl: false,
    },
    config: {
      method: 'get',
      urlStart: 'teams/',
      urlEnd: '/app/config',
      dynamicApiUrl: true,
    },
    notifications: {
      method: 'get',
      url: 'notifications',
      dynamicApiUrl: false,
    },
    ackNotifications: {
      method: 'post',
      urlStart: 'notifications/',
      urlEnd: '/acknowledge',
      dynamicApiUrl: false,
    },
    ackAllNotifications: {
      method: 'post',
      url: 'notifications/acknowledge-all',
      dynamicApiUrl: false,
    },
    dashboardOverviewData: {
      method: 'get',
      urlStart: 'teams/',
      urlEnd: '/dashboard/'+ env.apiAppName +'/overview',
      dynamicApiUrl: true,
    },
    dashboardStoreSales: {
      method: 'get',
      urlStart: 'teams/',
      urlEnd: '/dashboard/'+ env.apiAppName +'/store',
      dynamicApiUrl: true,
    },
    dashboardStoreStats: {
      method: 'get',
      urlStart: 'teams/',
      urlEnd: '/dashboard/'+ env.apiAppName +'/statistics/trends',
      dynamicApiUrl: true,
    },
    dashboardWebsiteOverview: {
      method: 'get',
      urlStart: 'teams/',
      urlEnd: '/dashboard/'+ env.apiAppName +'/website',
      dynamicApiUrl: true,
    },
    statisticsTrendsData: {
      method: 'get',
      urlStart: 'teams/',
      urlEnd: '/dashboard/'+ env.apiAppName +'/statistics/trends',
      dynamicApiUrl: true,
    },
    statisticsStoresData: {
      method: 'get',
      urlStart: 'teams/',
      urlEnd: '/dashboard/'+ env.apiAppName +'/statistics/stores',
      dynamicApiUrl: true,
    },
    statisticsWebsiteData: {
      method: 'get',
      urlStart: 'teams/',
      urlEnd: '/dashboard/'+ env.apiAppName +'/statistics/websites',
      dynamicApiUrl: true,
    },
    statisticsAdvertisingData: {
      method: 'get',
      urlStart: 'teams/',
      urlEnd: '/dashboard/'+ env.apiAppName +'/statistics/advertising',
      dynamicApiUrl: true,
    },
    marketingAdvertisingData: {
      method: 'get',
      urlStart: 'teams/',
      urlEnd: '/dashboard/'+ env.apiAppName +'/advertising',
      dynamicApiUrl: true,
    },
    statisticsSeoData: {
      method: 'get',
      urlStart: 'teams/',
      urlEnd: '/dashboard/'+ env.apiAppName +'/statistics/seo',
      dynamicApiUrl: true,
    },
    integrationTypes: {
      method: 'get',
      urlStart: 'teams/',
      urlEnd: 'integration-types/app',
      dynamicApiUrl: true,
    },
    integrationsByTeamToken: {
      method: 'get',
      urlStart: 'teams/',
      urlEnd: '/integrations',
      dynamicApiUrl: true,
    },
    integrationByToken: {
      method: 'get',
      url:'integrations',
      dynamicApiUrl: true,
    },
    integrationConfiguration: {
      method: 'get',
      urlStart:'integrations/',
      urlEnd: '/configuration',
      dynamicApiUrl: true,
    },
    createIntegration: {
      method: 'post',
      urlStart: 'teams/',
      urlEnd: '/integrations',
      dynamicApiUrl: true,
    },
    accountIdentifiers: {
      method: 'get',
      urlStart: 'integrations/',
      urlEnd: '/account-identifiers',
      dynamicApiUrl: true,
    },
    patchIntegration: {
      method: 'patch',
      urlStart: 'integrations/',
      urlEnd: '',
      dynamicApiUrl: true,
    },
    deleteIntegration: {
      method: 'delete',
      urlStart: 'integrations/',
      urlEnd: '',
      dynamicApiUrl: true,
    },
    updateUserDetails: {
      method: 'patch',
      url: 'users/',
      dynamicApiUrl: false,
    },
    deleteUserAccount: {
      method: 'delete',
      url: 'users/',
      dynamicApiUrl: false,
    },
    reauthorizeIntegration: {
      method: 'patch',
      urlStart: 'integrations/',
      urlEnd: '/authorization-url',
      dynamicApiUrl: true,
    },
    initializeFcmToken: {
      method: 'post',
      url: 'app/initialize/fcm-token',
      dynamicApiUrl: false,
    },
    initializeApp: {
      method: 'post',
      url: 'app/initialize',
      dynamicApiUrl: false,
    },
    logout: {
      method: 'post',
      url: 'users/logout',
      dynamicApiUrl: false,
    },
    conversions: {
      method: 'get',
      urlStart: 'teams/',
      urlEnd: '/conversions',
      dynamicApiUrl: true,
    },
    orders: {
      method: 'get',
      urlStart: 'teams/',
      urlEnd: '/orders',
      dynamicApiUrl: true,
    },
    orderDetail: {
      method: 'get',
      urlStart: 'integrations/',
      urlEnd: '/order',
      dynamicApiUrl: true,
    },
    signup: {
      method: 'post',
      url: 'sign-up',
      dynamicApiUrl: false,
    },
    userStatus: {
      method: 'get',
      url: 'users/status',
      dynamicApiUrl: false,
    },
    resendVerification: {
      method: 'post',
      url: 'verification/resend',
      dynamicApiUrl: false,
    },
    getCityNameByCountryAndPostal: {
      method: 'get',
      urlStart: 'sign-up/',
      urlEnd: '/city',
      dynamicApiUrl: false,
    },
    availableHighlights: {
      method: 'get',
      urlStart: 'teams/',
      urlEnd: '/app/available-highlights',
      dynamicApiUrl: true,
    },
    availableTrends: {
      method: 'get',
      urlStart: 'teams/',
      urlEnd: '/app/available-trends',
      dynamicApiUrl: true,
    },
    getActionPropositions: {
      method: 'get',
      urlStart: 'teams/',
      urlEnd: '/actions/propositions',
      dynamicApiUrl: true,
    },
    submitAction: {
      method: 'post',
      urlStart: 'teams/',
      urlEnd: '/actions',
      dynamicApiUrl: true,
    },
    getInsights: {
      method: 'get',
      urlStart: 'teams/',
      urlEnd: '/insights',
      dynamicApiUrl: true,
    },
    getCustomers: {
      method: 'get',
      url: 'customers',
      dynamicApiUrl: false,
    },
    getCustomerOrders: {
      method: 'get',
      urlStart: 'customers/',
      urlEnd: '/orders',
      dynamicApiUrl: false,
    },
    getCustomerOrderDetail: {
      method: 'get',
      url: 'orders/',
      dynamicApiUrl: false,
    },
    adWizard: {
      method: 'post',
      urlStart: 'teams/',
      urlEnd: '/ad-wizard',
      dynamicApiUrl: true,
    },
    adWizardAutoComplete: {
      method: 'get',
      urlStart: 'teams/',
      urlEnd: '/ad-wizard/autocomplete',
      dynamicApiUrl: true,
    },
    adWizardCommit: {
      method: 'post',
      urlStart: 'teams/',
      urlEnd: '/ad-wizard/commit',
      dynamicApiUrl: true,
    },
    adWizardPreview: {
      method: 'post',
      urlStart: 'teams/',
      urlEnd: '/ad-wizard/preview',
      dynamicApiUrl: true,
    },
    adWizardPreUpload: {
      method: 'get',
      urlStart: 'teams/',
      urlEnd: '/ad-wizard/pre-upload',
      dynamicApiUrl: true,
    },
    adPublishStatus: {
      method: 'get',
      urlStart: 'teams/',
      urlEnd: '/ad-wizard/results/',
      dynamicApiUrl: true,
    },
    enterpriseCampaigns: {
      method: 'get',
      urlStart: 'enterprise/',
      urlEnd: '/contents/campaigns',
      dynamicApiUrl: true,
    },
    getEffektifyNews: {
      method: 'get',
      url: 'news/',
      dynamicApiUrl: false,
    },
    listKeywords: {
      method: 'get',
      urlStart: 'teams/',
      urlEnd: '/keywords',
      dynamicApiUrl: true,
    },
    oAuthLogin: {
      method: 'post',
      url: 'auth/social/login',
      dynamicApiUrl: false,
    },
    oAuthRegister: {
      method: 'post',
      url: 'auth/social/register',
      dynamicApiUrl: false,
    },
    oAuthSessionValidate: {
      method: 'get',
      url: 'auth/social/session/',
      dynamicApiUrl: false,
    },
    oAuthSessionAuthorize: {
      method: 'post',
      urlStart: 'auth/social/session/',
      urlEnd: '/authorize',
      dynamicApiUrl: false,
    },
    oAuthSDKAuthorize: {
      method: 'post',
      url: 'auth/social/authorize',
      dynamicApiUrl: false,
    },
    getCampaign: {
      method: 'get',
      url: 'campaigns/',
      dynamicApiUrl: true,
    },
    patchCampaign: {
      method: 'patch',
      url: 'campaigns/',
      dynamicApiUrl: true,
    },
  }
}
