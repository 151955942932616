// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-footer {
  height: 60px;
}
ion-footer .custom-button {
  margin: 0;
  height: 60px;
  color: var(--ion-text-color);
  --background: var(--ion-color-primary);
  --background-hover: var(--ion-color-primary);
  --background-activated: var(--ion-color-primary);
  --background-focused: var(--ion-color-primary);
}`, "",{"version":3,"sources":["webpack://./src/app/components/app-tracking-info/app-tracking-info.component.scss"],"names":[],"mappings":"AACA;EACE,YAAA;AAAF;AACE;EACA,SAAA;EACA,YAAA;EACA,4BAAA;EACA,sCAAA;EACA,4CAAA;EACA,gDAAA;EACA,8CAAA;AACF","sourcesContent":["\nion-footer {\n  height: 60px;\n  .custom-button {\n  margin: 0;\n  height: 60px;\n  color: var(--ion-text-color);\n  --background: var(--ion-color-primary);\n  --background-hover: var(--ion-color-primary);\n  --background-activated: var(--ion-color-primary);\n  --background-focused: var(--ion-color-primary);\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
