import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app-service/app.service';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-create-campaign',
  templateUrl: './create-campaign.component.html',
  styleUrls: ['./create-campaign.component.scss'],
})
export class CreateCampaignComponent implements OnInit {

  constructor(
    public appService: AppService,
    private navCtrl: NavController
  ) { }

  ngOnInit() {
  }

  navigatePage(url: string): void {
    this.navCtrl.navigateForward(url);
  }

  openAdwizard(){
    this.navCtrl.navigateForward("/ad-wizard")
  }
}
