import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { GlobeIconComponent } from 'src/app/components/svg/globe-icon/globe-icon.component';
import { ConnectionsIconComponent } from 'src/app/components/svg/connections-icon/connections-icon.component';
import { MarketingIconComponent } from 'src/app/components/svg/marketing-icon/marketing-icon.component';
import { StatIconComponent } from 'src/app/components/svg/stat-icon/stat-icon.component';
import { ClockIconComponent } from 'src/app/components/svg/clock-icon/clock-icon.component';
import { CheveronIconComponent } from 'src/app/components/svg/cheveron-icon/cheveron-icon.component';
import { DomainIconComponent } from 'src/app/components/svg/domain-icon/domain-icon.component';
import { CaretIconComponent } from 'src/app/components/svg/caret-icon/caret-icon.component';
import { OptionsIconComponent } from 'src/app/components/svg/options-icon/options-icon.component';
import { EIconComponent } from 'src/app/components/svg/e-icon/e-icon.component';
import { CloseIconComponent } from 'src/app/components/svg/close-icon/close-icon.component';
import { CheckedIconComponent } from 'src/app/components/svg/checked-icon/checked-icon.component';
import { CloseModalIconComponent } from 'src/app/components/svg/close-modal-icon/close-modal-icon.component';
import { EarningsIconComponent } from 'src/app/components/svg/earnings-icon/earnings-icon.component';
import { ConnectStoreComponent } from 'src/app/components/connect-store/connect-store.component';
import { CreateCampaignComponent } from './create-campaign/create-campaign.component';
import { ConversionRateIconComponent } from 'src/app/components/svg/conversion-rate-icon/conversion-rate-icon.component';
import { MediumChartIconComponent } from 'src/app/components/svg/medium-chart-icon/medium-chart-icon.component';
import { LowChartIconComponent } from 'src/app/components/svg/low-chart-icon/low-chart-icon.component';
import { OpenNewComponent } from 'src/app/components/svg/open-new/open-new.component';
import { SearchIconComponent } from 'src/app/components/svg/search-icon/search-icon.component';
import { CompletedIconComponent } from 'src/app/components/svg/completed-icon/completed-icon.component';
import { BackIconComponent } from 'src/app/components/svg/back-icon/back-icon.component';
import { CloseWhiteIconComponent } from 'src/app/components/svg/close-white-icon/close-white-icon.component';
import { MessageSentIconComponent } from 'src/app/components/svg/message-sent-icon/message-sent-icon.component';
import { SortIconComponent } from 'src/app/components/svg/sort-icon/sort-icon.component';
import { CheveronRightComponent } from 'src/app/components/svg/cheveron-right/cheveron-right.component';
import { SearchBigComponent } from 'src/app/components/svg/search-big/search-big.component';
import { ExternalIconComponent } from 'src/app/components/svg/external-icon/external-icon.component';
import { WebShopIconComponent } from 'src/app/components/svg/web-shop-icon/web-shop-icon.component';
import { CheckIconBigComponent } from 'src/app/components/svg/check-icon-big/check-icon-big.component';
import { ConnectionIconComponent } from 'src/app/components/svg/connection-icon/connection-icon.component';
import { CheckedCircleIconComponent } from 'src/app/components/svg/checked-circle-icon/checked-circle-icon.component';
import { CloseSmallIconComponent } from 'src/app/components/svg/close-small-icon/close-small-icon.component';
import { MoreBigIconComponent } from 'src/app/components/svg/more-big-icon/more-big-icon.component';
import { SquareIconComponent } from 'src/app/components/svg/square-icon/square-icon.component';
import { SvgComponent } from 'src/app/components/app-svg';
import { BookMeetingComponent } from './book-meeting/book-meeting.component';
import { CreateCopyComponent } from 'src/app/components/svg/create-copy/create-copy.component';
import { CreateImageComponent } from 'src/app/components/svg/create-image/create-image.component';
import { CreateVideoComponent } from 'src/app/components/svg/create-video/create-video.component';
import { BoostSeoComponent } from 'src/app/components/svg/boost-seo/boost-seo.component';
import { AddKeywordsComponent } from 'src/app/components/svg/add-keywords/add-keywords.component';
import { BookAppointmentComponent } from 'src/app/components/svg/book-appointment/book-appointment.component';
import { InfoIconComponent } from 'src/app/components/svg/info-icon/info-icon.component';
import { SwitchAccountComponent } from 'src/app/components/svg/switch-account/switch-account.component';
import { InsightsIconComponent } from 'src/app/components/svg/insights-icon/insights-icon.component';
import { OffersIconComponent } from 'src/app/components/svg/offers-icon/offers-icon.component';
import { NotificationIconComponent } from 'src/app/components/svg/notification-icon/notification-icon.component';
import { HelpIconComponent } from 'src/app/components/svg/help-icon/help-icon.component';
import { SettingsIconComponent } from 'src/app/components/svg/settings-icon/settings-icon.component';
import { UserDeleteIconComponent } from 'src/app/components/svg/user-delete-icon/user-delete-icon.component';
import { LogoutIconComponent } from 'src/app/components/svg/logout-icon/logout-icon.component';
import { LogoMainComponent } from 'src/app/components/svg/logo-main/logo-main.component';
import { LanguageIconComponent } from 'src/app/components/svg/language-icon/language-icon.component';
import { ModeIconComponent } from 'src/app/components/svg/mode-icon/mode-icon.component';
import { SearchableSelectComponent } from './searchable-select/searchable-select.component';
import { AdwizardAutocompleteComponent } from './adwizard-autocomplete/adwizard-autocomplete.component';
import { WebHeaderComponent } from './web-header/web-header.component';
import { EditIconComponent } from 'src/app/components/svg/edit-icon/edit-icon.component';
import { LockIconComponent } from 'src/app/components/svg/lock-icon/lock-icon.component';
import { TrashCanIconComponent } from 'src/app/components/svg/trash-can-icon/trash-can-icon.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { SearchByPipe } from 'src/app/pipes/search-by/search-by.pipe'; // Search by pipe
import { AppIconComponent } from 'src/app/components/svg/app-icon/app-icon.component';
import { OfflineIconComponent } from './svg/offline-icon/offline-icon.component';
import { ConnectionsComponent } from './connections/connections.component';
import { PlusCircleComponent } from 'src/app/components/svg/plus-circle/plus-circle.component'; 
import { MinusCircleComponent } from 'src/app/components/svg/minus-circle/minus-circle.component';
import { OnlineIconComponent } from 'src/app/components/svg/online-icon/online-icon.component';
import { AdPublishNilComponent } from './svg/ad-publish-nil/ad-publish-nil.component';
import { AdPublishSucessComponent } from './svg/ad-publish-sucess/ad-publish-sucess.component';
import { AdPublishErrorComponent } from './svg/ad-publish-error/ad-publish-error.component';
import { AdPublishProcessingComponent } from './svg/ad-publish-processing/ad-publish-processing.component';
import { SanitizePipe } from 'src/app/pipes/sanitize/sanitize.pipe'; // Sanitize pipe
import { MenuToggleComponent } from './svg/menu-toggle/menu-toggle.component';
import { OrdersComponent } from 'src/app/components/orders/orders.component'
import { OrderDetailComponent } from 'src/app/components/order-detail/order-detail.component'
import { ArrowControlComponent } from './svg/arrow-control/arrow-control.component';
import { LogoSmallestComponent } from './svg/logo-smallest/logo-smallest.component';
import { SourcesIconComponent } from './svg/sources-icon/sources-icon.component';
import { NewAccountComponent } from './svg/new-account/new-account.component';
import { ExpertsComponent } from './svg/experts/experts.component';
import { DynamicContainerComponent } from './dynamic-container/dynamic-container.component';
import { EffektifyNewsComponent } from './effektify-news/effektify-news.component';
import { FilterComponent } from './filter/filter.component';
import { MystoreStatsComponent } from './mystore-stats/mystore-stats.component';
import { FailedIconComponent } from './svg/failed-icon/failed-icon.component';
import { ConnectionPlugIconComponent } from './svg/connection-plug-icon/connection-plug-icon.component';
import { CheckIconComponent } from './svg/check-icon/check-icon.component';
import { BackIconAdComponent } from './svg/back-icon-ad/back-icon-ad.component';
import { DemoVideoOverlayComponent } from '../modals/demo-video-overlay/demo-video-overlay.component';
import { MarkdownModule } from 'ngx-markdown';
import { SocialIconsComponent } from './svg/social-icons/social-icons.component';
import { GraphComponent } from './graph/graph.component';
import { DemoBannerComponent } from 'src/app/components/demo-banner/demo-banner.component';
import { UserIconComponent } from './svg/user-icon/user-icon.component';
import { HeaderMenuComponent } from 'src/app/components/header-menu/header-menu.component';
import { ProcessInfoIconComponent } from './svg/process-info-icon/process-info-icon.component';
import { EditCampaignsComponent } from './edit-campaigns/edit-campaigns.component';
import { AppTrackingInfoComponent } from './app-tracking-info/app-tracking-info.component';
import { CampaignTemplateCardComponent } from './campaign-template-card/campaign-template-card.component';
import { IntegrationConfigurationsComponent } from './integration-configurations/integration-configurations.component';
import { CampaignsPreviewComponent } from './campaigns-preview/campaigns-preview.component';
import { CustomSelectComponent } from './custom-select/custom-select.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    TranslateModule,
    MarkdownModule.forRoot()
  ],
  declarations: [
    GlobeIconComponent,
    ConnectionsIconComponent,
    MarketingIconComponent,
    StatIconComponent,
    ClockIconComponent,
    CheveronIconComponent,
    DomainIconComponent,
    CaretIconComponent,
    OptionsIconComponent,
    EIconComponent,
    CloseIconComponent,
    CheckedIconComponent,
    CloseModalIconComponent,
    EarningsIconComponent,
    ConnectStoreComponent,
    ConversionRateIconComponent,
    MediumChartIconComponent,
    LowChartIconComponent,
    OpenNewComponent,
    SearchIconComponent,
    CompletedIconComponent,
    BackIconComponent,
    CloseWhiteIconComponent,
    MessageSentIconComponent,
    SortIconComponent,
    CheveronRightComponent,
    SearchBigComponent,
    ExternalIconComponent,
    WebShopIconComponent,
    CheckIconBigComponent,
    ConnectionIconComponent,
    CheckedCircleIconComponent,
    CloseSmallIconComponent,
    MoreBigIconComponent,
    SquareIconComponent,
    SvgComponent,
    BookMeetingComponent,
    CreateCampaignComponent,
    CreateCopyComponent,
    CreateImageComponent,
    CreateVideoComponent,
    BoostSeoComponent,
    AddKeywordsComponent,
    BookAppointmentComponent,
    InfoIconComponent,
    SwitchAccountComponent,
    InsightsIconComponent,
    OffersIconComponent,
    NotificationIconComponent,
    HelpIconComponent,
    SettingsIconComponent,
    UserDeleteIconComponent,
    LogoutIconComponent,
    LanguageIconComponent,
    SearchableSelectComponent,
    EditIconComponent,
    LockIconComponent,
    TrashCanIconComponent,
    ModeIconComponent,
    TermsConditionsComponent,
    SearchByPipe,
    AppIconComponent,
    OfflineIconComponent,
    AdwizardAutocompleteComponent,
    WebHeaderComponent,
    LogoMainComponent,
    ConnectionsComponent,
    PlusCircleComponent,
    MinusCircleComponent,
    OnlineIconComponent,
    AdPublishNilComponent,
    AdPublishSucessComponent,
    AdPublishErrorComponent,
    AdPublishProcessingComponent,
    SanitizePipe,
    MenuToggleComponent,
    OrdersComponent,
    OrderDetailComponent,
    ArrowControlComponent,
    LogoSmallestComponent,
    SourcesIconComponent,
    NewAccountComponent,
    ExpertsComponent,
    EffektifyNewsComponent,
    DynamicContainerComponent,
    FilterComponent,
    MystoreStatsComponent,
    FailedIconComponent,
    ConnectionPlugIconComponent,
    CheckIconComponent,
    BackIconAdComponent,
    DemoVideoOverlayComponent,
    SocialIconsComponent,
    GraphComponent,
    DemoBannerComponent,
    UserIconComponent,
    HeaderMenuComponent,
    ProcessInfoIconComponent,
    EditCampaignsComponent,
    AppTrackingInfoComponent,
    CampaignTemplateCardComponent,
    IntegrationConfigurationsComponent,
    CampaignsPreviewComponent,
    CustomSelectComponent
  ],
  exports: [
    GlobeIconComponent,
    ConnectionsIconComponent,
    MarketingIconComponent,
    StatIconComponent,
    ClockIconComponent,
    CheveronIconComponent,
    DomainIconComponent,
    CaretIconComponent,
    OptionsIconComponent,
    EIconComponent,
    CloseIconComponent,
    CheckedIconComponent,
    CloseModalIconComponent,
    EarningsIconComponent,
    ConnectStoreComponent,
    ConversionRateIconComponent,
    MediumChartIconComponent,
    LowChartIconComponent,
    OpenNewComponent,
    SearchIconComponent,
    CompletedIconComponent,
    BackIconComponent,
    CloseWhiteIconComponent,
    MessageSentIconComponent,
    SortIconComponent,
    CheveronRightComponent,
    SearchBigComponent,
    ExternalIconComponent,
    WebShopIconComponent,
    CheckIconBigComponent,
    ConnectionIconComponent,
    CheckedCircleIconComponent,
    CloseSmallIconComponent,
    MoreBigIconComponent,
    SquareIconComponent,
    SvgComponent,
    BookMeetingComponent,
    CreateCampaignComponent,
    CreateCopyComponent,
    CreateImageComponent,
    CreateVideoComponent,
    BoostSeoComponent,
    AddKeywordsComponent,
    BookAppointmentComponent,
    InfoIconComponent,
    SwitchAccountComponent,
    InsightsIconComponent,
    OffersIconComponent,
    NotificationIconComponent,
    HelpIconComponent,
    SettingsIconComponent,
    UserDeleteIconComponent,
    LogoutIconComponent,
    LanguageIconComponent,
    SearchableSelectComponent,
    EditIconComponent,
    LockIconComponent,
    TrashCanIconComponent,
    ModeIconComponent,
    TermsConditionsComponent,
    SearchByPipe,
    AppIconComponent,
    OfflineIconComponent,
    AdwizardAutocompleteComponent,
    WebHeaderComponent,
    LogoMainComponent,
    ConnectionsComponent,
    PlusCircleComponent,
    MinusCircleComponent,
    OnlineIconComponent,
    AdPublishNilComponent,
    AdPublishSucessComponent,
    AdPublishErrorComponent,
    AdPublishProcessingComponent,
    SanitizePipe,
    MenuToggleComponent,
    OrdersComponent,
    OrderDetailComponent,
    ArrowControlComponent,
    LogoSmallestComponent,
    SourcesIconComponent,
    NewAccountComponent,
    ExpertsComponent,
    EffektifyNewsComponent,
    DynamicContainerComponent,
    FilterComponent,
    MystoreStatsComponent,
    FailedIconComponent,
    ConnectionPlugIconComponent,
    CheckIconComponent,
    BackIconAdComponent,
    DemoVideoOverlayComponent,
    SocialIconsComponent,
    GraphComponent,
    DemoBannerComponent,
    UserIconComponent,
    HeaderMenuComponent,
    ProcessInfoIconComponent,
    EditCampaignsComponent,
    AppTrackingInfoComponent,
    CampaignTemplateCardComponent,
    IntegrationConfigurationsComponent,
    CampaignsPreviewComponent,
    CustomSelectComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule { }
