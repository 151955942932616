export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const dynamicChartBorderColor = 'rgba(255,153,153, 1)';
export const dynamicChartBackgroundColor = 'rgba(255,153,153, 0.5)';
export const unreadNotificationMaxText = '99+';
export const defaultChartSegmentColor = '#CCCCCC';
export const defaultDashedTooltipColor =  '#d4d4d4';
export const lineChartBorderColor = '#aaaaaa';
export const gridLineColor = 'rgba(128, 128, 128, 0.2)';
export const dashboardPageUrl = '/tabs/dashboard';
export const marketingPageUrl = '/tabs/marketing';
export const onboardPageUrl = '/onboard';
export const minListCollapseCount = 5;
export const defaultNoDataErrorTemplateOffsetTop = 275;
export const defaultNoDataErrorTemplateOffsetTopDemoUser = 375;
export const popularFilterKey = 'popular';
export const sortAsc = 'asc';
export const sortDesc = 'desc';
