import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Preferences } from '@capacitor/preferences';
import { dashboardPageUrl } from 'src/app/constants/app-constants';

const unauthenticatedNavs = [
  '/onboard',
  '/forgot-password',
  '/register',
  '/login',
  '/email-login',
  '/signup-account',
  '/signup-business'
];

const dashboardNav = [dashboardPageUrl];

const guestNav = ['/onboard'];

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(private router: Router) {}

  async canActivate(): Promise<boolean> {
    const userAuth =  await Preferences.get({ key: 'auth' });
    const userData =  await Preferences.get({ key: 'user' });
    const navUrl = this.router.getCurrentNavigation().extractedUrl.toString();
    if (unauthenticatedNavs.includes(navUrl) && userAuth?.value && userData?.value) {
      this.router.navigate(dashboardNav);
    } else if (!unauthenticatedNavs.includes(navUrl) && !userAuth?.value && !userData?.value) {
      this.router.navigate(guestNav);
    } else {
      return true;
    }
  }
  async canLoad(): Promise<boolean> {
    const userAuth =  await Preferences.get({ key: 'auth' });
    const userData =  await Preferences.get({ key: 'user' });
    const navUrl = this.router.getCurrentNavigation().extractedUrl.toString();
    if (userAuth?.value && userData?.value) {
      if (unauthenticatedNavs.includes(navUrl)) {
        this.router.navigate(dashboardNav);
      } else {
        return true;
      }
    } else {
      if (unauthenticatedNavs.includes(navUrl)) {
        return true;
      } else {
        this.router.navigate(guestNav);
      }
    }
  }
}
